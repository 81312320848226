import React from "react"
import { graphql } from 'gatsby'

import Crate from "../components/crate"
import PageTemplate from '../components/pageTemplate'
import MainContainer from "../components/mainContainer"

class RegistrationPage extends React.Component {

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale

    const title = "Registration"
    const post = data.wordpressPage;

    const content_left = post.acf.page_registration.content_left;
    const content_right = post.acf.page_registration.content_right;

    return (
      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate className="bg-white">
          <MainContainer className="px-5 sm:px-0 py-3">
            <div className="flex flex-wrap -mx-5 py-10">
              <div className="w-full md:w-1/2 lg:w-6/12 px-5">
                <div className="text-osm text-gray-900" dangerouslySetInnerHTML={{ __html: content_left }}></div>
              </div>
              <div className="w-full md:w-1/2 lg:w-6/12 px-5">
                <div className="text-osm text-gray-900" dangerouslySetInnerHTML={{ __html: content_right }}></div>
              </div>
            </div>
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }
}

export default RegistrationPage

export const query = graphql`
  query($pId: Int!, $pType: String!) {
      wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      title
      acf{
        page_registration{
          content_left
          content_right
        }
      }
    }
    headerImage: file(relativePath: { eq: "jesedu-global-icon-white.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`